import { URLS } from 'constants/api'

export const QUERY_KEYS = {
  carDetail: (carId?: string, args?: Record<string, unknown>) => [
    URLS.carDetail(carId),
    args
  ],
  carStateUpdate: (carId?: string, args?: Record<string, unknown>) => [
    URLS.carStateUpdate(carId),
    args
  ],
  carInfoPdf: (carId?: string, args?: Record<string, unknown>) => [
    URLS.getCarInfoPdf(carId),
    args
  ],
  deleteCarPhotos: (
    args?: Record<string, unknown>,
    body?: Record<string, unknown>
  ) => [URLS.deleteCarDetailPhotos, args, body],
  searchCars: (args?: Record<string, unknown>) => [URLS.searchCars, args],
  lovBodies: (args?: Record<string, unknown>) => [URLS.lovBodies, args],
  lovMakes: (args?: Record<string, unknown>) => [URLS.lovMakes, args],
  lovFuelTypes: (args?: Record<string, unknown>) => [URLS.lovFuelTypes, args],
  lovTransmissions: (args?: Record<string, unknown>) => [
    URLS.lovTransmissions,
    args
  ],
  lovModelsSearch: (args?: Record<string, unknown>) => [
    URLS.lovModelsSearch,
    args
  ],
  lovEnginesSearch: (args?: Record<string, unknown>) => [
    URLS.lovEnginesSearch,
    args
  ],
  searchFiles: (args?: Record<string, unknown>) => [URLS.searchFiles, args],
  carAggregators: (carId?: string, args?: Record<string, unknown>) => [
    URLS.carAggregators(carId),
    args
  ],
  carAggregatorExport: (carId?: string) => [URLS.carAggregatorExport(carId)],
  getSalesStatesStats: (args?: Record<string, unknown>) => [
    URLS.getSalesStatesStats,
    args
  ],
  lovMarketingLabels: (args?: Record<string, unknown>) => [
    URLS.lovMarketingLabels,
    args
  ],
  lovMarketingHighlights: (args?: Record<string, unknown>) => [
    URLS.lovMarketingHighlights,
    args
  ],
  searchServiceOrders: (args?: Record<string, unknown>) => [
    URLS.searchServiceOrders,
    args
  ],
  serviceOrderDetail: (serviceOrderDetailId?: string) => [
    URLS.serviceOrderDetail(serviceOrderDetailId)
  ],
  lovStandardRepairs: (args?: Record<string, unknown>) => [
    URLS.lovStandardRepairs,
    args
  ],
  lovServiceContractors: (args?: Record<string, unknown>) => [
    URLS.lovServiceContractors,
    args
  ],
  lovTechnicalConditionsSections: (args?: Record<string, unknown>) => [
    URLS.lovTechnicalConditionSections,
    args
  ],
  lovTechnicalConditionItems: (args?: Record<string, unknown>) => [
    URLS.lovTechnicalConditionItems,
    args
  ],
  lovTechnicalConditionSubcategorySearch: (args?: Record<string, unknown>) => [
    URLS.lovTechnicalConditionSubcategorySearch,
    args
  ],
  lovTechnicalConditionCategorySearch: (args?: Record<string, unknown>) => [
    URLS.lovTechnicalConditionCategorySearch,
    args
  ],
  vehicleCosts: (carId?: string) => URLS.vehicleCosts(carId),
  vinBlockList: (args?: Record<string, unknown>) => [URLS.vinBlockList, args],
  carsReporting: URLS.carsReporting,
  carStateHistory: (carId?: string) => URLS.carStateHistory(carId),
  carPriceHistory: (carId?: string) => URLS.carPriceHistory(carId),
  carLocationHistory: (carId?: string) => URLS.carLocationHistory(carId),
  searchOrders: (args?: Record<string, unknown>) => [URLS.searchOrders, args],
  orderDetail: (orderId?: string) => URLS.orderDetail(orderId),
  orderNotes: URLS.orderNotes,
  orderHomeDeliveryInfo: URLS.orderHomeDeliveryInfo,

  customerDetail: (customerId?: string) => URLS.customerDetail(customerId),
  customerSelf: URLS.customerSelf,
  customerSessionRefresh: URLS.customerSessionRefresh
}
